<template>
  <div>
    <general-table
      :api-url="APIURL"
      :add-type="addType"
      :add-component-name="addComponentName"
      :columns="columns"
      guard="transactions"
    >
      <template #cell(id)="data">
        #{{ data.item.id }}
      </template>
      <template #cell(name)="data">
        <b-media vertical-align="center">
          <template
            v-if="data.item.image"
            #aside
          >
            <b-avatar
              size="32"
              :src="data.item.image"
            />
          </template>
          <span
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.username }}
          </span>
        </b-media>
      </template>
      <template
        #cell(confirmed)="data"
        class="text-center"
      >
        <b-avatar
          :id="`transaction-row-${data.item.id}`"
          size="32"
          :variant="resolveTransactinsStatusVariantAndIcon(data.item.confirmed).variant"
          class="text-center"
        >
          <feather-icon :icon="resolveTransactinsStatusVariantAndIcon(data.item.confirmed).icon" />
        </b-avatar>
      </template>
      <template
        #cell(created_at)="data"
        class="text-center"
      >
        {{ timeAgo(data.item.created_at) }}
      </template>
      <template #cell(method)="data">
        <b-avatar
          :id="`method-row-${data.item.id}`"
          size="32"
          :variant="resolveTransactionsMethod(data.item.method).variant"
          class="text-center"
        >
          <feather-icon :icon="resolveTransactionsMethod(data.item.method).icon" />
        </b-avatar>
        <b-tooltip
          v-if="data.item.method"
          :target="`method-row-${data.item.id}`"
          placement="top"
        >
          <p class="mb-0 text-capitalize">
            {{ data.item.method.replace('_', ' ') }}
          </p>
        </b-tooltip>
      </template>
    </general-table>
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: 'transactions?transaction_type=C-C',
      addType: '',
      addComponentName: 'add-requests',
      editComponent: 'edit-requests',
      columns: [
        { key: 'id', label: '#', sortable: true },
        {
          key: 'confirmed',
          label: 'Confirmation',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        { key: 'payable.username', label: 'Name', sortable: true },
        { key: 'amount', label: 'Amount', sortable: true },
        {
          key: 'method',
          label: 'Method',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'created_at',
          label: 'Date',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
    }
  },
  setup() {
    const resolveTransactinsStatusVariantAndIcon = status => {
      if (status === true) return { variant: 'light-success', icon: 'CheckCircleIcon' }

      return { variant: 'light-danger', icon: 'InfoIcon' }
    }

    const resolveTransactionsMethod = method => {
      if (method === 'received_money') return { variant: 'light-success', icon: 'ArrowDownRightIcon' }

      return { variant: 'light-info', icon: 'ArrowUpLeftIcon' }
    }

    return {
      resolveTransactinsStatusVariantAndIcon,
      resolveTransactionsMethod,
    }
  },
  methods: {
    // eslint-disable-next-line consistent-return
    timeAgo(input) {
      const date = (input instanceof Date) ? input : new Date(input)
      const formatter = new Intl.RelativeTimeFormat('en')
      const ranges = {
        years: 3600 * 24 * 365,
        months: 3600 * 24 * 30,
        weeks: 3600 * 24 * 7,
        days: 3600 * 24,
        hours: 3600,
        minutes: 60,
        seconds: 1,
      }
      const secondsElapsed = (date.getTime() - Date.now()) / 1000
      // eslint-disable-next-line no-restricted-syntax
      for (const key in ranges) {
        if (ranges[key] < Math.abs(secondsElapsed)) {
          const delta = secondsElapsed / ranges[key]
          return formatter.format(Math.round(delta), key)
        }
      }
    },
  },
}
</script>

<style>

</style>
